<template>
  <div class="constrain mb-12 md:mb-20">
    <div class="row-12">
      <div class="md:offset-3 md:col-9">
        <h1 class="leading-none" v-html="payload.seitentitel" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
};
</script>

<style lang="scss" scoped>
.constrain {
  margin-top: 12rem;
  @screen sm {
     margin-top: 4rem;
  }
}
</style>
